export default {
  selectors: {
    flyoutAnchor: 'a[data-openingbehaviour="flyout"]',
    flyoutDialog: '.batcom-flyout__dialog',
    flyoutWindow: '.batcom-flyout__window',
    flyoutBody: '.batcom-flyout__body',
    flyoutMask: '.batcom-flyout__mask',
    flyoutCloseButton: '.batcom-flyout__closebutton',
    flyoutButtons: '.batcom-flyout__buttons',
    flyoutFooterButtonContainer: '.batcom-flyout__footerbutton',
    flyoutFooterButton: '.cmp-button',
  },
  classes: {
    opened: 'opened',
    noScroll: 'noScroll',
    slideIn: 'slide-in',
    hasButtons: 'has-buttons',
  },
};
